import { VehicleId, VehicleMake, VehicleModel, VehicleRegistrationNumber } from '@api/endpoints'
import { VehiclesSearchQueryParams } from '@api/endpoints/vehicles'
import { QueryKey } from '@tanstack/react-query'

export const queryKeyGetBodyTypes: QueryKey = ['getBodyTypes']
export const queryKeyGetActiveBodyTypes: QueryKey = ['getActiveBodyTypes']
export const queryKeyGetActiveFuelTypes: QueryKey = ['getActiveFuelTypes']
export const queryKeyGetCars = (filters?: VehiclesSearchQueryParams): QueryKey => ['getCars', filters]
export const queryKeyGetOffers: QueryKey = ['getOffers']
export const queryKeyGetCarMakes: QueryKey = ['getCarMakes']
export const queryKeyGetVehicleById = (id: VehicleId): QueryKey => ['getVehicleById', id]
export const queryKeyGetVehicleOptionsById = (id: VehicleId): QueryKey => ['getVehicleOptionsById', id]
export const queryKeyGetCarModelsByMake = (make: VehicleMake): QueryKey => ['getCarModelsByMake', make]
export const queryKeyGetVehicleCountsByMakeAndModel = (make?: VehicleMake, model?: VehicleModel): QueryKey => [
  'getVehicleCountsByMakeAndModel',
  make,
  model
]
export const queryKeyFindVehicleByRegNumber = (regNumber: VehicleRegistrationNumber): QueryKey => [
  'findVehicleByRegNumber',
  regNumber
]
export const queryKeyFindVehicleFromMntByRegNumber = (regNumber: VehicleRegistrationNumber): QueryKey => [
  'findVehicleFromMntByRegNumber',
  regNumber
]
export const queryKeyGetMakes: QueryKey = ['getMakes']
