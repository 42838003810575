import { Price, VehicleMake, VehicleModel } from '@api/endpoints'
import {
  VehicleBase,
  VehicleFull,
  VehicleShort,
  VehicleSortByEnum,
  VehicleSortByEnumSchema,
  VehicleSortDirectionEnum,
  VehicleSortDirectionEnumSchema,
  VehiclesSearchQueryParams
} from './types'

export const createVehiclesShort = (obj: VehicleFull): VehicleShort => ({
  id: obj.id,
  discountedPrice: obj.discountedPrice,
  fullPrice: obj.fullPrice,
  make: obj.make,
  model: obj.model,
  modelExtra: obj.modelExtra,
  monthlyPaymentFrom: obj.monthlyPaymentFrom,
  fuel: obj.mainData.fuelGroup,
  mileage: obj.mainData.mileage,
  transmission: obj.mainData.transmission,
  wheelDrive: obj.mainData.wheelDrive,
  year: obj.mainData.year,
  // carWarranty: obj.carWarranty,
  isServiceBookPresent: obj.isServiceBookPresent,
  isBoughtNewFromEstonia: obj.isBoughtNewFromEstonia,
  thumbnailImageUrl: obj.attachments[0]?.thumbnailUrl
})

export const buildVehicleSortDirection = (sortBy: VehicleSortByEnum): VehicleSortDirectionEnum => {
  switch (sortBy) {
    case VehicleSortByEnumSchema.enum.PRICE:
      return VehicleSortDirectionEnumSchema.enum.ASC
    case VehicleSortByEnumSchema.enum.MILEAGE:
      return VehicleSortDirectionEnumSchema.enum.ASC
    case VehicleSortByEnumSchema.enum.YEAR:
      return VehicleSortDirectionEnumSchema.enum.DESC
    case VehicleSortByEnumSchema.enum.NEWLY_ADDED:
      return VehicleSortDirectionEnumSchema.enum.DESC
  }
}

export const buildVehicleUrlSearchParams = (page: number, filters?: VehiclesSearchQueryParams): URLSearchParams => {
  const sortBy = filters?.sortBy ?? VehicleSortByEnumSchema.enum.YEAR
  const numToStr = (num: number): string => num.toString()
  const optNumToStr = (num?: number): string | undefined => (num ? numToStr(num) : undefined)
  const numArrayToStr = (numArray?: Array<number>): Array<string> | undefined =>
    numArray ? numArray.map(numToStr) : undefined

  const params: Record<string, string | Array<string> | undefined> = {
    variant: filters?.variants,
    make: filters?.make,
    model: filters?.model,
    fuels: filters?.fuels,
    transmissions: numArrayToStr(filters?.transmissions),
    wheelDrives: numArrayToStr(filters?.wheelDrives),
    priceFrom: optNumToStr(filters?.price?.from),
    priceTo: optNumToStr(filters?.price?.to),
    powerInKwFrom: optNumToStr(filters?.powerInKw?.from),
    powerInKwTo: optNumToStr(filters?.powerInKw?.to),
    yearFrom: optNumToStr(filters?.year?.from),
    yearTo: optNumToStr(filters?.year?.to),
    mileageFrom: optNumToStr(filters?.mileage?.from),
    mileageTo: optNumToStr(filters?.mileage?.to),
    engineDisplacementRangeFrom: optNumToStr(filters?.engineDisplacementRange?.from),
    engineDisplacementRangeTo: optNumToStr(filters?.engineDisplacementRange?.to),
    // warrantyTypeId: optNumToStr(filters?.warrantyTypeId),
    page: numToStr(page),
    sortBy: sortBy,
    sortDirection: buildVehicleSortDirection(sortBy)
  }
  const urlSearchParams = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      if (Array.isArray(value)) {
        value.forEach((val) => urlSearchParams.append(key, val))
      } else {
        urlSearchParams.append(key, value)
      }
    }
  })
  return urlSearchParams
}

export const buildVehicleCountUrlSearchParams = (make?: VehicleMake, model?: VehicleModel): URLSearchParams => {
  const params: Record<string, string | undefined> = {
    make: make,
    model: model
  }
  const urlSearchParams = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => (value ? urlSearchParams.append(key, value) : null))
  return urlSearchParams
}

export const getVehiclePrice = (vehicle: VehicleBase): Price => vehicle.discountedPrice ?? vehicle.fullPrice
