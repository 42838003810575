/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { OptionalType } from '@digital-magic/ts-common-utils'
import { httpReceiveOnlyJson } from '@digital-magic/zod-fetch'
import { apiBaseUrlV1, apiBaseUrlV2 } from '@constants/configuration'
import {
  VehicleId,
  VehicleMake,
  VehicleMakeSchema,
  VehicleModel,
  VehicleModelSchema,
  VehicleRegistrationNumber
} from '@api/endpoints'
import {
  BodyTypeEnumSchema,
  FuelTypeEnumSchema,
  VehicleByRegNumberSchema,
  VehicleCountSchema,
  VehicleFullSchema,
  VehicleListResponseSchema,
  VehicleMntSearchResponseSchema,
  VehicleOptionResponseSchema,
  VehicleShortSchema,
  VehiclesSearchQueryParams
} from './types'
import { buildVehicleCountUrlSearchParams, buildVehicleUrlSearchParams } from './utils'

export const apiBaseUrlV1Cars = `${apiBaseUrlV1}/cars`
export const apiBaseUrlV2Cars = `${apiBaseUrlV2}/cars`

const bodyTypesUrl = `${apiBaseUrlV1Cars}/body-types`

const activeBodyTypesUrl = `${apiBaseUrlV2Cars}/active-body-types`

const fuelTypesUrl = `${apiBaseUrlV2Cars}/fuel-types`

const vehiclesUrl = (page: number, filters?: VehiclesSearchQueryParams): string =>
  `${apiBaseUrlV2Cars}?${buildVehicleUrlSearchParams(page, filters).toString()}`

const vehicleByIdUrl = (vehicleId: VehicleId): string => `${apiBaseUrlV1Cars}/${vehicleId}`
const vehicleOffersUrl = `${apiBaseUrlV1Cars}/offers`
const vehicleOptionsByIdUrl = (vehicleId: VehicleId): string => `${apiBaseUrlV1Cars}/${vehicleId}/options`
const vehicleMakesUrl = `${apiBaseUrlV1Cars}/makes`
const vehicleModelsByMakeUrl = (make: VehicleMake): string => `${apiBaseUrlV1Cars}/makes/${make}/models`
const vehicleCountsByMakeAndModelUrl = (make?: VehicleMake, model?: VehicleModel): string =>
  `${apiBaseUrlV1Cars}/count${make || model ? '?' + buildVehicleCountUrlSearchParams(make, model).toString() : ''}`
const vehicleByRegNumber = (regNumber: VehicleRegistrationNumber): string => `${apiBaseUrlV1Cars}/search/${regNumber}`
const vehicleFromMntByRegNumber = (regNumber: VehicleRegistrationNumber): string =>
  `${apiBaseUrlV1Cars}/mnt/${regNumber}`

export const getVehicleOffers = () =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: vehicleOffersUrl,
    responseSchema: VehicleShortSchema.array()
  })

export const getBodyTypes = () =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: bodyTypesUrl,
    responseSchema: BodyTypeEnumSchema.array()
  })

export const getActiveBodyTypes = () =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: activeBodyTypesUrl,
    responseSchema: BodyTypeEnumSchema.array()
  })

export const getActiveFuelTypes = () =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: fuelTypesUrl,
    responseSchema: FuelTypeEnumSchema.array()
  })

export const getVehicleList = (page: number, filters: OptionalType<VehiclesSearchQueryParams>) =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: vehiclesUrl(page, filters),
    responseSchema: VehicleListResponseSchema
  })

export const getVehicleById = (vehicleId: VehicleId) =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: vehicleByIdUrl(vehicleId),
    responseSchema: VehicleFullSchema
  })

export const getVehicleOptionsById = (vehicleId: VehicleId) =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: vehicleOptionsByIdUrl(vehicleId),
    responseSchema: VehicleOptionResponseSchema.array()
  })

export const getVehicleMakes = () =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: vehicleMakesUrl,
    responseSchema: VehicleMakeSchema.array()
  })

export const getVehicleModelsByMake = (make: VehicleMake) =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: vehicleModelsByMakeUrl(make),
    responseSchema: VehicleModelSchema.array()
  })

export const getVehicleCountsByMakeAndModel = (make?: VehicleMake, model?: VehicleModel) =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: vehicleCountsByMakeAndModelUrl(make, model),
    responseSchema: VehicleCountSchema
  })

export const findVehicleByRegNumber = (regNumber: VehicleRegistrationNumber) =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: vehicleByRegNumber(regNumber),
    responseSchema: VehicleByRegNumberSchema
  })

export const findVehicleFromMntByRegNumber = (regNumber: VehicleRegistrationNumber) =>
  httpReceiveOnlyJson({
    method: 'GET',
    url: vehicleFromMntByRegNumber(regNumber),
    responseSchema: VehicleMntSearchResponseSchema
  })
